<template>
    <van-uploader v-model="fileList" :max-count="1" :after-read="afterRead" />
</template>

<script>
import { reactive, toRefs } from 'vue'
import { Upload } from '@/api'
import { Toast } from 'vant'

export default {
    props: ['modelValue'],
    setup(props, context) {
        const state = reactive({
            fileList: []
        })

        const afterRead = file => {
            file.status = 'uploading'
            file.message = '上传中...'

            let img = file.file
            let formData = new FormData()
            formData.append(img.name, img)

            Upload(formData).then(res => {
                if (res.status) {
                    file.status = 'done'
                    file.message = '上传成功'
                    Toast.success('图片上传成功！')
                    context.emit('update:modelValue', res.data)
                } else {
                    file.status = 'failed'
                    file.message = '上传失败'
                    context.emit('update:modelValue', '')
                    Toast.fail(res.message)
                }
            })
        }

        return {
            ...toRefs(state),
            afterRead
        }
    }
}
</script>

<style lang="scss" scoped></style>
