<template>
    <div class="AddBankInformation">
        <div class="information">
            <van-cell :title="title" :value="value" is-link arrow-direction="down" @click="show = true" />
            <van-action-sheet v-model:show="show" title="选择付款方式">
                <div class="content">
                    <van-cell @click="onSelect('银行卡')">
                        <template #title>
                            <img :src="require('@/assets/img/pay_icon_1.png')" />
                            <span>银行卡</span>
                        </template>
                    </van-cell>

                    <van-cell @click="onSelect('支付宝')">
                        <template #title>
                            <img :src="require('@/assets/img/pay_icon_3.png')" />
                            <span>支付宝</span>
                        </template>
                    </van-cell>

                    <van-cell @click="onSelect('微信')">
                        <template #title>
                            <img :src="require('@/assets/img/pay_icon_2.png')" />
                            <span>微信</span>
                        </template>
                    </van-cell>
                </div>
            </van-action-sheet>

            <div class="add" v-if="title === '微信'">
                <van-field v-if="isShanghu" v-model="wx_real_name" label="姓名" placeholder="请输入姓名" />
                <van-field v-else v-model="realname" label="姓名" readonly />
                <van-field label="收款码">
                    <template #input>
                        <Uploader v-model="wx_qrcode" />
                    </template>
                </van-field>
            </div>

            <div class="add" v-else-if="title === '支付宝'">
                <van-field v-if="isShanghu" v-model="zfb_real_name" label="姓名" placeholder="请输入姓名" />
                <van-field v-else v-model="realname" label="姓名" readonly />
                <van-field v-model="zfb_account" label="账号" placeholder="支付宝账号" />
                <van-field label="收款码">
                    <template #input>
                        <Uploader v-model="zfb_qrcode" />
                    </template>
                </van-field>
            </div>

            <div class="add" v-else-if="title === '银行卡'">
                <van-field v-if="isShanghu" v-model="bank_real_name" label="姓名" placeholder="请输入姓名" />
                <van-field v-else v-model="realname" label="姓名" readonly />
                <van-cell :title="bank" is-link arrow-direction="down" @click="showBank = true" />
                <van-field v-model="bank_account" label="卡号" placeholder="请输入卡号" />
                <van-cell :title="cityTitle" is-link arrow-direction="down" @click="showCity = true" />
                <van-field v-model="bankAddress" label="开户行" placeholder="请输入开户行" />
            </div>

            <van-action-sheet v-model:show="showBank" title="请选择银行">
                <div class="bankContent">
                    <van-radio-group v-model="bankcode" @change="changeBank">
                        <van-radio class="bank" v-for="item in bankList" :key="item.BANK_CODE" :name="item.BANK_CODE">{{ item.BANK_NAME }}</van-radio>
                    </van-radio-group>
                </div>
            </van-action-sheet>

            <van-action-sheet v-model:show="showCity">
                <van-area class="cityContent" title="请开户城市" :area-list="areaList" :columns-num="2" @confirm="confirm" @cancel="showCity = false" />
            </van-action-sheet>
        </div>

        <van-button class="btn" type="primary" block round @click="onSubmit">添加</van-button>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getBankCode, addBank } from '@/api'
import { Toast } from 'vant'
import { areaList } from '@/assets/js/city.js'

import Uploader from '@/components/Uploader.vue'

export default {
    components: {
        Uploader
    },
    setup() {
        const state = reactive({
            title: '付款方式',
            value: '选择付款方式',
            show: false,
            realname: '',
            isShanghu: false,

            wx_real_name: '',
            wx_qrcode: '',

            zfb_real_name: '',
            zfb_account: '',
            zfb_qrcode: '',

            bank_real_name: '',
            bank_account: '',
            bankcode: '',
            bank: '请选择银行',
            bankList: [],
            showBank: false,

            showCity: false,
            cityTitle: '请选择开户城市',
            bankAddress: '',
            pay_address: ''
        })

        const router = useRouter()
        const store = useStore()

        state.realname = store.state.userInfo.REAL_NAME
        state.isShanghu = store.state.userInfo.USER_TYPE === '1'

        const onSelect = item => {
            state.show = false
            state.title = item
            state.value = ''
        }

        getBankCode().then(res => {
            state.bankList = res.data
        })

        const changeBank = BANK_CODE => {
            state.bankList.forEach(item => {
                if (item.BANK_CODE === BANK_CODE) {
                    state.bank = `选择银行：${item.BANK_NAME}`
                }
            })
            state.showBank = false
        }

        const onSubmit = () => {
            let params = {}

            if (state.title === '微信') {
                params = {
                    type: 2,
                    real_name: state.wx_real_name,
                    qrcode: state.wx_qrcode
                }
            } else if (state.title === '支付宝') {
                params = {
                    type: 3,
                    real_name: state.zfb_real_name,
                    account: state.zfb_account,
                    qrcode: state.zfb_qrcode
                }
            } else if (state.title === '银行卡') {
                state.pay_address = state.pay_address + ' - ' + state.bankAddress

                params = {
                    type: 1,
                    real_name: state.bank_real_name,
                    account: state.bank_account,
                    bankcode: state.bankcode,
                    pay_address: state.pay_address
                }
            } else {
                Toast.fail('请选择付款方式')
                return
            }

            addBank(params).then(res => {
                if (res.status) {
                    Toast.success('添加成功！')
                    router.replace('/BankInformation')
                } else {
                    Toast.fail(res.message)
                }
            })
        }

        const confirm = data => {
            state.cityTitle = `开户城市：${data[0].name} - ${data[1].name}`

            state.pay_address = `${data[0].name} - ${data[1].name}`
            state.showCity = false
        }

        return {
            ...toRefs(state),
            router,
            onSubmit,
            onSelect,
            changeBank,
            areaList,
            confirm
        }
    }
}
</script>

<style lang="scss" scoped>
.AddBankInformation {
    background: $background;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: h(30);

    .information {
        .content {
            padding: 16px 16px h(30);
            text-align: left;

            img {
                width: h(45);
                height: h(45);
                margin-right: w(20);
                vertical-align: middle;
            }

            span {
                font-size: 14px;
                line-height: 109.48%;
                color: #333333;
            }
        }

        .add {
            margin-top: h(15);
        }

        .bankContent {
            padding: 0 16px h(30);
            text-align: left;

            .bank {
                margin-top: h(20);
            }
        }

        .cityContent {
            margin: 10px;
        }
    }

    .btn {
        @include btn();
    }
}
</style>
